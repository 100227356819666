import React from 'react'
import { graphql, Link, PageProps, useStaticQuery } from 'gatsby'

import Grid from '../components/Grid'
import Footer from '../components/Footer'
import Layout from '../Layout'
import SEO from '../SEO'

import { Interview as InterviewType } from '../util/types'

interface Props extends PageProps {
    pageContext: { url: string }
}

const Interview: React.FC<Props> = ({ pageContext }) => {
    const data: { allSanityInterview: { nodes: InterviewType[] } } = useStaticQuery(graphql`
        query {
            allSanityInterview(sort: { order: ASC, fields: title }) {
                nodes {
                    url
                    title
                    summary
                    subtitle
                    interviewee
                    date
                    cover {
                        asset {
                            url
                        }
                    }
                    more_info {
                        url
                        label
                    }
                    links {
                        url
                        label
                    }
                    video_url
                    video_duration
                }
            }
        }
    `)

    const interview = data.allSanityInterview.nodes.filter(i => i.url === pageContext.url)[0]
    const rest = data.allSanityInterview.nodes.filter(i => i.url !== pageContext.url) || []

    const lastInterview = rest.pop()
    if (lastInterview) rest.unshift(lastInterview)

    if (!interview) return <p>Error</p>

    return (
        <div>
            <Layout currentPage="interviews">
                <SEO title="Was Wa(h)r - Interviews" />
            </Layout>

            <div className="interview">
                <div className="interview-video">
                    <video src={interview.video_url} controls poster={interview.cover.asset.url} />
                </div>
                <div className="interview-content">
                    <h3 className="interview-title">
                        {interview.title}
                        <br />
                        {interview.subtitle}
                    </h3>
                    <p className="interview-details">
                        {interview.interviewee}
                        <br />
                        {new Date(interview.date).toLocaleDateString('de-DE', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                        })}
                    </p>
                    <p className="interview-summary">{interview.summary}</p>
                    <div className="interview-moreinfo">
                        <a href={interview.more_info.url} target="_blank">
                            {interview.more_info.label}
                        </a>
                    </div>
                    {interview.links && interview.links.length > 0 && (
                        <div className="interview-links">
                            <p>Weiterführende Links:</p>
                            {interview.links.map((link, i) => (
                                <a key={i} href={link.url} target="_blank">
                                    {link.label}
                                </a>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <Grid
                title="Weitere Interviews die dich interessieren könnten"
                items={rest.filter((_d, i) => i <= 2)}
                noBorder
            />

            <div className="interview-button">
                <Link to="/interviews">
                    <button>{'>>>'} Zurück zur Übersicht</button>
                </Link>
            </div>

            <Footer />
        </div>
    )
}

export default Interview
